import React from "react";
import { useForm } from "react-hook-form";
// import styles from '../../../assets/styles/form.module.scss'

import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { setData, postData, postEmail } from '../../verificationSlice';


import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import { listTempUserDatas } from '../../../../graphql/queries';

Amplify.configure(awsconfig);

const input_props = [
  {
    name: "email",
    placeholder: "メールアドレス",
    required: '必須項目です。',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "メールアドレスが正しくありません。"
    }
  },
]

const Form = (props) => {
  const { styles, redirectPath } = props

  const { register, handleSubmit, errors, formState } = useForm();
  const { isSubmitting } = formState;

  // emailの送信状況も判定に追加。
  const data_status = useSelector(state => state.verification.data_status)
  const email_status = useSelector(state => state.verification.email_status)
  const isSending = (data_status === 'sending') || (email_status === 'sending')
  const isFailed = (data_status === 'failed') || (email_status === 'failed')

  const def_data = useSelector(state => state.registration.data);
  const dispatch = useDispatch();

  const onSubmit = async data => {
    data.email = data.email.toLowerCase()
    dispatch(setData(data));
    const email = data.email
    try {
      const postData_result = await dispatch(postData(email))
      // rejectedの場合は例外をスロー
      unwrapResult(postData_result);
      const reseption_id = postData_result.payload.data.createTempUserData.id
      const post_email_data = {
        id: reseption_id,
        email: email
      }
      const postEmail_result = await dispatch(postEmail(post_email_data))
      // rejectedの場合は例外をスロー
      unwrapResult(postEmail_result);

      props.history.push(redirectPath);
    } catch (err) {
      console.log('Failed to send the data: ', err);
    }
  };

  return (

    <>
      { isFailed && <div className={styles.prosess_error_message}>エラーが発生しました。しばらく時間を置いてからお試しください。</div> }

      <form onSubmit={handleSubmit(onSubmit)} className={styles.registration}>
        {
          input_props.map((item, index) => (

            <div className={`${styles.input_row} ${errors[item.name] ? styles.input_has_error : ''}`} key={index} >
              {
                (() => {
                  switch (item.type) {
                    default:
                      return (
                        <>
                        <span className={styles.input_value}>
                          <input placeholder={item.placeholder} name={item.name} ref={register(
                            {
                              required: item.required,
                              ...(item.pattern ? { pattern: {
                                  value: new RegExp(item.pattern.value),
                                  message: item.pattern.message
                              }} : null),
                              ...(item.max ? { max: item.max } : null),
                              ...(item.min ? { min: item.min } : null),
                              ...(item.maxLength ? { maxLength: item.maxLength } : null),
                              ...(item.minLength ? { minLength: item.minLength } : null),
                              validate: async value => {
                                const res = await API.graphql(graphqlOperation(
                                  listTempUserDatas,
                                  {filter: { "email": { "eq": value.toLowerCase() }}}
                                ));
                                return (res.data.listTempUserDatas.items.length) ? '同じメールアドレスで無料お試しの登録する場合は、お申し込みから24時間経過後にお申し込みください。' : true
                              },
                            }
                          )} defaultValue={ def_data[item.name] ? def_data[item.name]: ''}
                          autoFocus={true} />
                        </span>
                        { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
                        { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
                        </>
                      )
                  }
                })()
              }
            </div>
          ))
        }
        <div className={styles.submit_btn_wrapper}>
          <button type="submit" className={styles.confirm_btn} disabled={isSending || isSubmitting}>
            { isSending ? <span>送信中<span className={styles.sending}></span></span> : <span>無料で試してみる</span> }
          </button>
        </div>
        <span className={styles.notes_wrapper}>メールが迷惑メールフォルダに振り分けられることがあります。メールが届かない場合は、弊社にて登録いたしますので、お手数ですがWebサポート窓口までご連絡ください。</span>
      </form>
    </>
  );
}

export default withRouter(Form);
