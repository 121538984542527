import React from "react";
import styles from '../../../assets/styles/form.module.scss'
import { withRouter } from "react-router-dom";
import { UilFastMail } from '@iconscout/react-unicons'

const Thanks = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.complete}>
          <p className={styles.success_message}>無料お試しのお申し込みありがとうございます。</p>
          <div className={styles.explanation_wrapper}>
            <div className={styles.notice}>
              <div className={styles.icon_wrapper}>
                <UilFastMail width="100%" height="100%" color="#3ea757" />
              </div>
              <div>
                <p className={styles.title}>登録はまだ完了していません。</p>
                <p>ご登録用のメールを送信いたしましたのでご確認ください。</p>
                <p>メールに記載のURLより、ご登録をお願いいたします。</p>
                <p>（メールの有効期限は24時間です）</p>
              </div>
            </div>
            <ul>
              <li>15分経ってもメールが届かない場合は、弊社にて登録いたしますので、Webサポート窓口までお問い合わせください。</li>
            </ul>
          </div>
          <div className={styles.policy_wrapper}>
            <p>
              <a href='https://www.farend.co.jp/contact/web-support/' target='_blank' rel='noopener noreferrer'>Webサポート窓口</a><br />
              サポート対応時間：8:30〜17:00(土曜日・日曜日・祝日・弊社休業日を除く)
            </p>
            <p className={styles.text_center}><a href='https://hosting.redmine.jp/' target='_blank' rel='noopener noreferrer'>My Redmineのトップページに戻る</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Thanks);
